<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "AddLeadActivity",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "2.6 How to add Lead activity?",
          description: [
            {
              text: "1. You can add new activity to the  contact timeline by tapping the blue “Add activity” button on the right. ",
              imgType: true,
              img: "44.Contact info_tab 2 – 1.png",
            },
            {
              text: "2. You will see “Add activity” bottom pop up. There are multiple selections of activities which you can add into the timeline. ",
              imgType: true,
              img: "80.Contact_add activity.png",
            },
            {
              text: "3. Add the details of the activity. Each activity will have a different form field.",
              imgType: true,
              img: "82.Contact_add activity-phone call.png",
            },
            {
              text: "4. Once you’re done editing, tap the “Save activity” button to save the change on the contact.",
              imgType: false,
            },
            {
              text: "5. You will see a green alert to indicate that the changes have been made",
              imgType: true,
              img: "44.Contact info_tab 2 – 2.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
